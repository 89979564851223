<template>
  <v-container fluid style="padding: 0">
    <v-card class="white py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <div class="d-flex align-center">
        <v-icon color="blue" @click="back">mdi-chevron-left</v-icon>
        <v-btn text color="blue" @click="back" class="font-weight-bold text-capitalize">{{
          $t('projects.create_project.back_to_projects')
        }}</v-btn>
      </div>
      <div class="d-flex align-center text-start mt-7">
        <div style="position: relative; display: inline-block">
          <v-avatar size="70">
            <img :src="selectedProject.logo || require('@/assets/png/project.png')" alt="logo" /> 
          </v-avatar>

          <v-icon size="14px" style="position: absolute; bottom: 5px; right: 5px">mdi-camera</v-icon>
        </div>

        <template v-if="!editTitle">
          <div class="ml-4" @mouseenter="visibleTitle = true" @mouseleave="visibleTitle = false">
            <div class="font-weight-bold text-h6 d-flex align-center">
              {{ selectedProject.name }}
              <v-icon class="ml-2" v-if="visibleTitle" role="button" @click="handleClickEditTag"
                >mdi-pencil-outline</v-icon
              >
              <!-- <v-icon class="ml-2" v-if="visibleTitle" role="button"
                @click="handleClickEditTag">mdi-pencil-outline</v-icon> -->
            </div>
            <div class="mt-1 text-h6 gray-ish--text">{{ selectedProject.key }}</div>
            <div class="mt-1 gray-ish--text">{{ selectedProject?.customFields?.description }}</div>
          </div>
        </template>
        <template v-else>
          <div class="ml-4">
            <div class="font-weight-bold text-h6 d-flex align-center">
              <v-text-field dense class="font-weight-bold text-h6" v-model="projectTitle" />
            </div>
            <div class="mt-1 gray-ish--text">
              <v-text-field dense class="mt-1 gray-ish--text" v-model="projectSubTitle" />
            </div>
          </div>
        </template>
      </div>
      <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
        <v-row justify="space-between" class="align-center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <SearchComponent @update:search="setSearchFilter"></SearchComponent>
            <ProjectUserFilter></ProjectUserFilter>
          </v-col>
          <v-col cols="12" md="auto">
            <v-row justify="end" class="align-center">
              <SettingsMenu :filterItems="filteredMenuHeaders" />
            </v-row>
          </v-col>
        </v-row>
        <Table :filteredHeaders="headers" :items="filteredItem" :itemKey="itemKey" />
      </v-card>
      <!--
      <div class="mt-6 d-flex">
        <v-chip
          @click="all()"
          :class="{ 'blue--text': filter === 'all' }"
          width="200px"
          :color="filter === 'all' ? 'blue-light' : 'gray-light'"
          label
        >
          <div class="font-weight-bold px-2">
            {{ $t('all') }} <span class="ml-2">{{ allCount }}</span>
          </div>
        </v-chip>
        <div class="ml-2">
          <v-chip
            @click="select()"
            :class="{ 'blue--text': filter === 'selected' }"
            width="200px"
            :color="filter === 'selected' ? 'blue-light' : 'gray-light'"
            label
          >
            <div class="font-weight-bold px-2">
              {{ $t('selected') }} <span class="ml-2">{{ selectedCount }}</span>
            </div>
          </v-chip>
        </div>
      </div>
      <div class="d-flex align-center justify-space-between mt-5">
        <div class="d-flex align-center">
          <SearchComponent :search="search" @update:search="search = $event" :placeholder="placeholder"></SearchComponent>
          <ProjectItemFilter
            :selectedRoles="selectedRoles"
            :selectedTags="selectedTags"
            @selectedRoles="handleSelectedRoles"
            @selectedTags="handleSelectedTags"
          />
        </div>
        <div class="d-flex align-center">
          <v-sheet
            color="gray-100"
            width="50px"
            class="d-flex ml-4 align-center justify-center"
            height="50px"
            rounded="lg"
            v-on="on"
          >
            <v-icon color="gray-ish">mdi-cog-outline</v-icon>
          </v-sheet>
        </div>
      </div>
      <div>
        <v-row class="mt-3">
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="(role, index) in selectedRoles"
            :key="index"
            label
          >
            {{ $t('role') }}: {{ role }}
          </v-chip>
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="(tag, index) in selectedTags"
            :key="index"
            label
          >
            {{ $t('tag') }}: {{ tag }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            v-if="selectedTags.length > 0 || selectedRoles.length > 0"
            @click="clearAll"
            >{{ $t('clearAll') }}</v-btn
          >
        </v-row>
      </div>
      <v-data-table
        :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
        class="custom-table mt-6"
        :headers="headers"
        :items="filteredUsers"
        :item-key="itemKey"
        :class="tableClass"
      >
        <template v-slot:[`header.name`]="{ header }">
          <div class="d-flex align-center">
            <div class="custom-div mr-2"></div>
            <span class="header_text">{{ header.text }}</span>
            <v-icon size="18" color="gray-ish">mdi-chevron-down</v-icon>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td class="d-flex align-center">
            <div class="custom-div mr-2"></div>
            <v-avatar class="mr-2" size="40">
              <img :src="item.image" width="100%" alt="logo" />
            </v-avatar>
            <div class="text-start">
              <div class="text-subtitle-2 font-weight-bold">{{ item.name }}</div>
            </div>
          </td>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <td class="d-flex justify-space-between align-center">
            <span>{{ item.email }}</span>
            <v-btn icon>
              <v-icon color="gray-ish" @click="selectItem(item)">mdi-plus-box-outline</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <td class="text-start">
            <v-menu offset-y :position-y="10">
              <template #activator="{ on }">
                <div v-on="on" role="button">
                  {{ item.role }}
                  <v-icon class="ml-3">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item @click="handleUpdateRole(role, item.id)" v-for="role in defaultRoles" :key="role">
                  <v-icon>mdi-building</v-icon>
                  {{ role }}
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
        <template v-slot:[`item.tag`]="{ item }">
          <td class="text-start">
            <v-menu offset-y :position-y="10">
              <template #activator="{ on }">
                <div v-on="on" role="button">
                  {{ `${item.tag[0]} +${item.tag.length - 1}` }}
                  <v-icon class="ml-3">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list class="pt-4">
                <v-list-item v-for="tag in item.tag" :key="tag" class="m-0">
                  <div class="d-flex align-start">
                    <v-checkbox dense v-model="checkbox" class="mb-0" />
                    <div class="d-flex align-center mt-2">
                      <span class="mr-2">{{ tag }}</span>
                      <v-icon class="mr-2" size="16" @click="handleEditTag(tag)">mdi-pencil-outline</v-icon>
                      <DeleteIcon />
                    </div>
                  </div>
                </v-list-item>
                <v-list-item @click="handleCreateTag()">
                  <v-icon class="mr-2" color="blue">mdi-plus</v-icon>
                  <span class="blue--text" role="button">{{ $t('createTag') }}</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
      </v-data-table>
      -->

      <div class="d-flex justify-end mt-5">
        <v-btn color="gray-100" width="150px" class="mr-4 text-capitalize" elevation="0" @click="onProjectCancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue" dark width="150px" elevation="0" class="text-capitalize" @click="onProjectSave">
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="drawer" fullscreen>
      <v-card class="pa-4">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between align-center">
              <h5 class="font-weight-bold">
                {{ !title ? $t('tagCreationHeader') : $t('tagEditHeader') }}
              </h5>
              <v-btn icon @click="drawer = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('titleLabel')" filled :placeholder="$t('enterTitle')" v-model="title" />
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('descriptionLabel')" filled :placeholder="$t('enterDescription')"
                v-model="description" />
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('typeLabel')" filled :placeholder="$t('enterType')" v-model="type" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn color="gray-100" full-width class="w-full text-capitalize" elevation="0" @click="drawer = false">
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="primary" class="w-full text-capitalize" depressed @click="onTagSave">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { items, users } from '@/constants/data.js';
import { createNamespacedHelpers } from 'vuex';
import Table from '@/components/Project/ProjectViewUserTable.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import makeOrgService from '@/services/api/org';

const { mapState: mapProjectState, mapActions } = createNamespacedHelpers('project');
const { mapState: mapUserState } = createNamespacedHelpers('user');

export default {
  name: 'ProjectDetailView',
  components: {
    Table,
    SearchComponent,
    ProjectUserFilter,
    SettingsMenu
  },
  data() {
    return {
      placeholder: 'Search by name, email, etc',
      selectedRoles: [],
      selectedTags: [],
      search: '',
      items: items,
      itemKey: 'uid',
      users: users,
      options: ['all', 'selected'],
      project: null,
      headers: [
        {
          text: this.$t('user'),
          align: 'start',
          sortable: false,
          value: 'name',
          class: 'rounded-l-lg gray-ish--text',
        },
        {
          text: this.$t('role'),
          value: 'role',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('lastActivity'),
          value: 'lastActivity',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('projectsName'),
          value: 'project',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('tag'),
          value: 'tag',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('email'),
          value: 'email',
          sortable: true,
          class: 'rounded-r-lg gray-ish--text',
        },
      ],
      tableClass: '',
      checkbox: false,
      defaultRoles: ['Account admin', 'Project Admin', 'QA Lead', 'Tester', 'Client'],
      drawer: false,
      title: '',
      description: '',
      type: '',
      visibleTitle: false,
      editTitle: false,
      projectTitle: '',
      projectSubTitle: '',
      selectedProject: {},
      searchFilter: '',
      filter: 'active',
      activeUserCount: 0,
      activeMembers: [],
    };
  },
  methods: {
    ...mapActions(['get', 'update']),
    back() {
      this.$router.go(-1);
    },
    async getMembers(per_page, next_page, handle) {
      const orgService = makeOrgService(this.$api);
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        if (next_page == 0) {
          let membersCount = response.data.totalMembers;
          this.activeUserCount = membersCount;
        }

      } catch (err) {
        console.log(err)
      }
    },
    all() {
      this.filter = 'all';
    },
    select() {
      this.filter = 'selected';
    },
    handleSelectedRoles(roles) {
      this.selectedRoles = roles;
    },
    handleSelectedTags(tags) {
      this.selectedTags = tags;
    },
    setSearchFilter(searchText) {
      this.searchFilter = searchText;
    },
    handleUpdateRole(role, userID) {
      let _users = [...this.users];
      this.users = _users.map((user) => {
        if (user.id === userID) {
          return {
            ...user,
            role,
          };
        } else return user;
      });
    },
    handleClickEditTag() {
      this.editTitle = true;
      this.projectSubTitle = this.selectedProject.customFields.description;
      this.projectTitle = this.selectedProject.name;
    },
    handleCreateTag() {
      this.title = '';
      this.description = '';
      this.type = '';
      this.drawer = !this.drawer;
    },
    handleEditTag(tag) {
      this.title = tag;
      this.description = '';
      this.type = '';
      this.drawer = !this.drawer;
    },
    clearAll() {
      this.selectedRoles = [];
      this.selectedTags = [];
    },
    onProjectCancel() {
      this.users.forEach((user) => {
        user.selected = false;
      });
      this.$router.go(-1);
    },
    onProjectSave() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          description: this.projectSubTitle,
        },
        name: this.projectTitle,
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        oldProject: this.selectedProject,
        payload,
      });
    },
    onTagSave() { }, // TODO
    selectItem(item) {
      item.selected = true;
    },
    async init(handle) {
      await this.getMembers(30, 0, handle);
    },
  },
  computed: {
    ...mapProjectState(['projects']),
    ...mapUserState(['currentAccount']),
    paramID() {
      return this.$route.params.id;
    },
    customCheckboxClass() {
      return {
        'custom-checkbox': true,
      };
    },
    allCount() {
      return this.users.length;
    },
    selectedCount() {
      return this.users.filter((item) => item.selected === true).length;
    },
    filteredItem() {
      let filtered =
        this.filter === 'active'
          ? this.activeMembers
          : this.filter === 'pending'
            ? this.pendingInvites
            : this.requestedUsers;

      if (this.searchFilter.length > 0) filtered = filtered.filter((item) => this.matchesFilter(item));

      return filtered;
    },
    filteredUsers() {
      let filtered = this.users;

      if (this.filter === 'all') {
        filtered = this.users;
      } else if (this.filter === 'selected') {
        filtered = filtered.filter((item) => item.selected === true);
      }

      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter((item) => {
          return item.name.toLowerCase().includes(searchTerm);
        });
      }

      if (this.selectedTags.length > 0) {
        filtered = filtered.filter((user) => {
          return this.selectedTags.every((tag) => user.tag.includes(tag));
        });
      }

      if (this.selectedRoles.length > 0) {
        filtered = filtered.filter((user) => {
          return this.selectedRoles.every((role) => user.role.includes(role));
        });
      }

      return filtered;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },

  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      console.log("not run")
      next();
    }
  },
  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async created() {
    if (this.projects.length < 1) {
      await this.get(this.$route.params.handle);
    }
    this.selectedProject = this.projects.find((project) => project.key === this.$route.params.key);
  },
};
</script>

<style>
.custom-checkbox .v-input--selection-controls .v-input--checkbox .v-input__control .v-icon {
  border: none !important;
}

.custom-checkbox input[type='checkbox'] {
  border-radius: 20px;
  width: 16px;
  height: 16px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-head__wrap {
  padding: 0 !important;
}

.custom-div {
  height: 15px;
  width: 15px;
  background-color: #eaecf0;
  border-radius: 4px;
}
</style>
